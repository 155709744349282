<template>
	<div class="WithdrawAccount">
		
		<div class="Top">
			<div class="Left">
				<el-button type="primary" icon="el-icon-arrow-left" @click="$router.go(-1)">返回</el-button>
			</div>
			<div class="Right">
				<span>
					账号一旦提交，只能删除/启用/停用，不能修改提现资料
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="One">
				
				<li>
					<span>收款方式</span>
					<em>
						
						<el-radio-group v-model="WithdrawAccount.Type">
						  <el-radio-button label="bank" >银行卡</el-radio-button>
						  <el-radio-button label="alipay">支付宝</el-radio-button>
						  <el-radio-button label="wechat">微信</el-radio-button>
						</el-radio-group>
						
					</em>
				</li>
				
				<li v-if="WithdrawAccount.Type == 'bank'">
					<span>开户银行</span>
					<em>
						<el-select v-model="WithdrawAccount.BankName">
							<el-option :value="bank" :label="bank" v-for="(bank,bankI) in BankList" :key="bankI"></el-option>
						</el-select>
						
						<span v-if="WithdrawAccount.BankName == '其它银行'" style="width: 200px;margin-left: 10px;display: inline-block;">
							<el-input placeholder="请输入银行名称" v-model="OtherBankName"></el-input>
						</span>
						
					</em>
				</li>
				
				<li>
					<span>
						<template v-if="WithdrawAccount.Type == 'bank'">持卡人姓名</template>
						<template v-else-if="WithdrawAccount.Type == 'alipay'">支付宝姓名</template>
						<template v-if="WithdrawAccount.Type == 'wechat'">微信昵称</template>
					</span>
					<em>
						<el-input style="width: 200px;" v-model="WithdrawAccount.Holder" ></el-input>
					</em>
				</li>
				
			
				
				<li>
					<span>
						<template v-if="WithdrawAccount.Type == 'bank'">银行卡号</template>
						<template v-else-if="WithdrawAccount.Type == 'alipay'">支付宝账号</template>
						<template v-if="WithdrawAccount.Type == 'wechat'">微信账号</template>
					</span>
					<em>
						<el-input style="width: 400px;" v-model="WithdrawAccount.No" ></el-input>
					</em>
				</li>
				
				
				
				<template v-if="WithdrawAccount.Id != ''">
					
					
					<li>
						<span>创建时间</span>
						<em>{{WithdrawAccount.CreatedAt}}</em>
					</li>	
					<li>
						<span>修改时间</span>
						<em>{{WithdrawAccount.UpdatedAt}}</em>
					</li>	
				</template>
				
				<li v-if="WithdrawAccount.Id == ''">
					<span></span>
					<em>
						<el-button type="primary" @click="UpdateWithdrawAccount()">提交</el-button>
					</em>
				</li>
			</div>
		</div>

	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,RadioButton,RadioGroup} from 'element-ui'
	export default {
	  name: 'WithdrawAccountNew',
	  props: {
	  },
	  data() {
	      return {
			  BankName:'',
			  OtherBankName:'',
			  BankList:[
				  '中国工商银行',
				  '中国建设银行',
				  '中国农业银行',
				  '中国邮政储蓄银行',
				  '中国银行',
				  '交通银行',
				  '招商银行',
				  '中国光大银行',
				  '浦发银行',
				  '华夏银行',
				  '广发银行',
				  '中信银行',
				  '兴业银行',
				  '中国民生银行',
				  '杭州银行',
				  '上海银行',
				  '宁波银行',
				  '平安银行',
				  '上海农村商业银行',
				  '鞍山银行',
				  '北京农村商业银行',
				  '北京银行',
				  '渤海银行',
				  '常熟农商银行',
				  '长沙银行',
				  '成都银行',
				  '浙江稠州商业银行',
				  '东莞银行',
				  '大连银行',
				  '德阳银行',
				  '东莞农村商业银行',
				  '东营银行',
				  '富滇银行',
				  '福建海峡银行',
				  '广东省农村信用社联合社',
				  '广州银行',
				  '桂林银行',
				  '贵阳银行',
				  '河北银行',
				  '汉口银行',
				  '徽商银行',
				  '恒丰银行',
				  '湖州银行',
				  '嘉兴银行',
				  '江苏省农村信用社联合社',
				  '江苏银行',
				  '晋城银行',
				  '晋中市商业银行',
				  '辽阳市商业银行',
				  '龙江银行',
				  '南昌银行',
				  '南海农商银行',
				  '南京银行',
				  '宁夏银行',
				  '内蒙古银行',
				  '齐商银行',
				  '青岛银行',
				  '绍兴银行',
				  '顺德农商银行',
				  '苏州银行',
				  '天津银行',
				  '台州银行',
				  '温州银行',
				  '乌鲁木齐市商业银行',
				  '吴江农村商业银行',
				  '浙江民泰商业银行',
				  '浙江省农村信用社联合社',
				  '浙商银行',
				  '重庆银行',
				  '浙江泰隆商业银行',
				  '自贡市商业银行',
				  '昆仑银行',
				  '华融湘江银行',
				  '云南省农村信用社',
				  '重庆农村商业银行',
				  '库尔勒商业银行',
				  '张家港农村商业银行',
				  '成都农商银行',
				  '莱商银行',
				  '上饶银行',
				  '天津农商银行',
				  '江南农村商业银行',
				  '鄂尔多斯银行',
				  '邢台银行',
				  '晋商银行',
				  '九江商业银行',
				  '吉林省农村信用社',
				  '江苏太仓农村商业银行',
				  '营口银行',
				  '遵义市商业银行',
				  '山东省农村信用社联合社',
				  '驻马店银行',
				  '赣州银行',
				  '西安银行',
				  '广西北部湾银行',
				  '无锡农村商业银行',
				  '广州农商银行',
				  '江苏江阴农村商业银行',
				  '平顶山银行',
				  '南充市商业银行',
				  '重庆三峡银行',
				  '韩亚银行',
				  '河北省农村信用社',
				  '宁夏黄河农村商业银行',
				  '阜新银行',
				  '新乡银行',
				  '中山小榄村镇银行',
				  '贵州省农村信用社',
				  '邯郸银行',
				  '锦州银行',
				  '乐山市商业银行',
				  '安徽农金安阳市商业银行',
				  '泰安市商业银行',
				  '鄞州银行',
				  '齐鲁银行',
				  '渣打银行',
				  '四川省农村信用社',
				  '三门峡银行',
				  '德州银行',
				  '周口银行',
				  '深圳农村商业银行',
				  '包商银行',
				  '青海银行',
				  '兰州银行',
				  '丹东银行',
				  '金华银行',
				  '朝阳银行',
				  '临商银行',
				  '广西省农村信用社',
				  '承德银行',
				  '郑州银行',
				  '盛京银行',
				  '玉溪市商业银行',
				  '抚顺银行',
				  '尧都农村商业银行',
				  '上海银行',
				  '阳泉市商业银行',
				  '湖北银行',
				  '潍坊银行',
				  '威海市商业银行',
				  '开封市商业银行',
				  '济宁银行',
				  '甘肃省农村信用社',
				  '湖南省农村信用社',
				  '江西省农村信用社',
				  '许昌银行',
				  '张家口市商业银行',
				  '信阳银行',
				  '河南省农村信用社',
				  '武汉农村商业银行',
				  '陕西信合宜宾市商业银行',
				  '湖北省农村信用社',
				  '攀枝花市商业银行',
				  '昆山农村商业银行',
				  '柳州银行',
				  '衡水市商业银行',
				  '石嘴山银行',
				  '南阳村镇银行',
				  '长治市商业银行',
				  '日照银行',
				  '汇丰银行',
				  '（中国）星展银行',
				  '（中国）乌海银行',
				  '商丘银行',
				  '新韩银行',
				  '广东华兴银行',
				  '哈尔滨银行',
				  '厦门银行',
				  '福建农村信用社农商银行',
				  '广东南粤银行',
				  '珠海华润银行',
				  '鹤壁银行',
				  '渤海银行',
				  '广西北部湾银行',
				  '天津滨海农村商业银行',
				  '吉林银行',
				  '漯河银行',
				  '其它银行',
			  ],
			  WithdrawAccount:{
				  Id:'',
				  Type:'bank',//账户类型 bank银行卡 alipay支付宝 wechat微信
				  BankName:'',
				  Holder:'',
				  No:'',
				  Status:50,
				  CreatedAt:'',
				  UpdatedAt:'',
			  },
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-radio-button':RadioButton,
		'el-radio-group':RadioGroup,
	  },
	  created() {
		if(this.WithdrawAccount.Id != ''){
			this.GetWithdrawAccount()
		}
	  },
	  methods:{
		UpdateWithdrawAccount(){

			let data = {
				Service:'Finance',
				Class: 'FinanceWithdrawAccount',
				Action: 'Add',
				Id:this.WithdrawAccount.Id,
				Type:this.WithdrawAccount.Type,
				BankName:this.WithdrawAccount.BankName,
				Holder:this.WithdrawAccount.Holder,
				No:this.WithdrawAccount.No,
				Status:this.WithdrawAccount.Status,
			}
			if(this.WithdrawAccount.Type == 'bank' && this.WithdrawAccount.BankName == '其它银行'){
				this.WithdrawAccount.BankName = this.OtherBankName
			}
			
			this.$post(this.$store.getters.getApiHost,data)
			.then((res) => {
				
				this.$message(res.Msg)
				
				if(res.ErrorId == 0){
					this.WithdrawAccount.Id = res.Data.Id
					this.GetWithdrawAccount()
				}
				
			})
			
		},
		GetWithdrawAccount(){
			
			let data = {
				Service:'Finance',
				Class: 'FinanceWithdrawAccount',
				Action: 'Get',
				Id:this.WithdrawAccount.Id,
			}
			
			this.$post(this.$store.getters.getApiHost,data)
			.then((res) => {
				
				if(res.ErrorId != 0){
					this.$message(res.Msg)
					return
				}
				
				this.WithdrawAccount = res.Data
			})
			
		}
	  }
	}
</script>

<style scoped>
.WithdrawAccount{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.WithdrawAccount .Top{
	display: flex;
	align-items: center;
}
.WithdrawAccount .Top .Left{
	
}
.WithdrawAccount .Top .Right{
	flex: 1;
	text-align: right;
	color: #999999;
}

.WithdrawAccount .List{
	margin-top: 20px;	
}
.WithdrawAccount .List .One{
	border: 1px solid rgba(0,0,0,0.04);
	padding: 20px;
	margin-bottom: 20px;
}
.WithdrawAccount .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.WithdrawAccount .List .One li span{
	width: 140px;
	color: rgba(0,0,0,0.3);
}
.WithdrawAccount .List .One li  em{
	flex: 1;
}
.WithdrawAccount .List .One li  em i{
	font-style: normal;
	color: rgba(0,0,0,0.3);
	margin-left: 10px;
}
.WithdrawAccount .List .One li i{
	font-style: normal;
	color: rgba(0,0,0,0.2);
}
</style>
